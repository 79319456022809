import { Chip } from '@mui/material';
import { ReactNode } from 'react';
import { getStatusValues } from 'shared/components/status-kpi-card/status-kpi-card.viewModel';
import { Status } from 'shared/models/status.type';
import { theme } from 'styles/theme';

export interface RAGDisplayProps {
  value: ReactNode;
  status: Status;
}

export const RAGDisplay = (props: RAGDisplayProps) => {
  const { value, status } = props;
  if (value === '') return <></>;
  const color = getStatusValues(status).color;
  const textColor =
    color === theme.palette.divider
      ? theme.palette.text.primary
      : 'rgba(0, 0, 0, 0.87)';
  return (
    <Chip
      sx={{
        color: textColor,
        backgroundColor: color,
        display: 'inline-block',
        height: '24px',
        paddingX: '4px',
        paddingY: '3px',
        borderRadius: '16px',
        fontSize: '13px',
        lineHeight: '18px',
        letter: '0.16px',
        textAlign: 'center',
        '&> span': {
          p: '0px 6px',
        },
        cursor: 'pointer',
      }}
      label={value}
    />
  );
};
