import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { theme } from 'styles/theme';
import { useGetFleetEeoiChart } from 'routes/environmental-monitor/services/fleet.service';
import { FixedTimeline } from 'assets/fixed-timeline';
import {
  yAxisTitle,
  getDomainMs,
} from 'routes/environmental-monitor/view-models/eeoi-chart.viewModel';
import { fleetPageTitles } from 'routes/environmental-monitor/view-models/titles.viewModel';

import { useContext } from 'react';

import { NavBarContext } from 'shared/models/navBar.context.model';
import { UTCDate } from 'shared/utils/date-utc-helper';
import {
  getTooltipDataIndex,
  MuiChartContainerWithLegend,
  tooltipContentProps,
} from 'shared/components/mui-chart/container.component';
import { LineSeriesType } from '@mui/x-charts-pro';
import { formatNumber } from 'shared/utils/float-utils';
import { nameof } from 'shared/components/datagrid';
import { CardContainer } from 'shared/components/navigation/cardContainer.component';

const domainMs = getDomainMs();

const secondaryGreen = theme.environmentalMonitor.colors.cyan;
type eeoiDataModel = {
  date: number;
  eeoiDeviation: number;
  voyageLegsCompleted: number;
};
const modelProperties = nameof<eeoiDataModel>;

// main component
export function EEOIChart() {
  const { imoNumbers } = useContext(NavBarContext);
  const eeoiData = useGetFleetEeoiChart(imoNumbers);
  const chartDataset: eeoiDataModel[] =
    eeoiData.data?.map((x) => {
      let date = new UTCDate(x.date);
      date = date?.startOfMonth!;
      x.date = date.unixTimeMs ?? 0;
      return {
        date: x.date,
        eeoiDeviation: x.eeoiDeviation ?? 0,
        voyageLegsCompleted: x.voyageLegsCompleted,
      };
    }) ?? [];

  const renderContent = () => {
    const chartSeries: LineSeriesType[] = [
      {
        type: 'line',
        dataKey: modelProperties('eeoiDeviation'),
        color: secondaryGreen,
        label: 'EEOI',
      },
    ];

    return (
      <Box component={'section'} sx={{ pl: 1, mb: 1 }}>
        <MuiChartContainerWithLegend
          dataset={chartDataset}
          loading={eeoiData.loading}
          error={eeoiData.error}
          yAxisProps={{ labelPosition: 34 }}
          margin={{ right: 40, left: 90 }}
          tooltip={{ content: (props) => customTooltip(props, chartDataset) }}
          series={chartSeries}
          xAxis={[
            {
              dataKey: modelProperties('date'),
              tickNumber: chartDataset.length + 1,
              min: domainMs.min,
              max: domainMs.max,

              scaleType: 'utc',
              tickLabelStyle: { angle: -35 },
              valueFormatter: dateFormatter,
            },
          ]}
          yAxis={[
            {
              label: yAxisTitle,
              valueFormatter: (value: number) => `${value}%`,
            },
          ]}
        ></MuiChartContainerWithLegend>
      </Box>
    );
  };

  return (
    <CardContainer
      title={fleetPageTitles.eeoi}
      icon={<FixedTimeline sx={{ margin: '5px 0px 0px 15px' }} />}
    >
      {renderContent()}
    </CardContainer>
  );
}

function customTooltip(props: tooltipContentProps, dataset: eeoiDataModel[]) {
  const index = getTooltipDataIndex(props);
  if (index === undefined) return null;

  const data = dataset[index];
  const eeoiDeviation = formatNumber(data?.eeoiDeviation) + '%';
  const voyageLegsCompleted = data?.voyageLegsCompleted;

  return (
    <Grid container width={230}>
      <Grid item xs={10}>
        <Typography>EEOI Deviation</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography align='right'>{eeoiDeviation}</Typography>
      </Grid>

      <Grid item xs={10}>
        <Typography>Voyage Legs Completed</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography align='right'>{voyageLegsCompleted}</Typography>
      </Grid>
    </Grid>
  );
}

const dateFormatter = (date: number) => {
  return UTCDate.fromMs(date).formatMY();
};
