import {
  Box,
  Checkbox,
  Chip,
  Grid,
  ListSubheader,
  MenuItem,
  SelectProps,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { IFuelType } from 'routes/environmental-monitor/models/fuelType.model';
import { IFuelDto } from 'routes/environmental-monitor/models/plannedItinerary.model';
import { ISequence, updateProperty } from '../modal/sequence.config';
import { formatNumber } from 'shared/utils/float-utils';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { SequenceNumberField } from 'shared/components/inputs/input-fields';
import { TEXT } from 'shared/constants/text';
import { groupBy } from 'shared/utils/display-utils';

// this function is used to render the fuel types in the select component
// shows the checkbox and the input field for the fuel consumption
export function ListFuelTypes(
  fuelTypes: IFuelType[],
  selectedFuelTypes: IFuelDto[],
  updateFuelCallBack: (field: keyof ISequence, value: any) => void
) {
  const allFuelTypes = [...fuelTypes]
    .slice()
    .sort(
      (a, b) =>
        a.classificationName.localeCompare(b.classificationName) ||
        a.abbreviation.localeCompare(b.abbreviation)
    );

  function handleValueChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fuelType: IFuelType
  ): void {
    const item = selectedFuelTypes.find((x) => x.fuelType === fuelType.name);
    if (item) {
      item.fuelConsumption = parseInt(event.target.value);
    } else {
      selectedFuelTypes.push({
        id: fuelType.enumValue,
        fuelType: fuelType.name,
        fuelConsumption: parseInt(event.target.value),
      });
    }
    const newValues = [...new Set(selectedFuelTypes)];
    updateFuelCallBack('fuelType', newValues);
  }

  const fuelTypesGrouped = groupBy(allFuelTypes, (x) => x.classificationName);
  const selectedFuelTypesNames = selectedFuelTypes?.map((x) => x.fuelType);

  const menuItems = Object.entries(fuelTypesGrouped).map(([key, value]) => {
    const lastItemIx = value?.length;
    return value?.map((fuelType, ix) => {
      const value = selectedFuelTypes?.find(
        (x) => x.fuelType === fuelType.name
      )?.fuelConsumption;

      const menuitemSx =
        ix + 1 === lastItemIx
          ? { pb: 2, borderBottom: '1px solid rgba(255, 255, 255, 0.09)' }
          : {};

      // we pass the fuel type id and name as the value of the menu item
      const menuItemValue = [fuelType.enumValue.toString(), fuelType.name];

      return (
        <MenuItem
          key={fuelType.enumValue}
          value={menuItemValue}
          sx={menuitemSx}
        >
          <Grid container>
            {/* display the classification name only once */}
            {ix === 0 && (
              <Grid item xs={12}>
                <ListSubheader
                  component={'div'}
                  sx={{ backgroundColor: 'transparent' }}
                >
                  {key}
                </ListSubheader>
              </Grid>
            )}

            <Grid item xs={6} sx={{ alignContent: 'center' }}>
              <Checkbox
                checked={selectedFuelTypesNames.includes(fuelType.name)}
              />
              {fuelType.abbreviation}
            </Grid>
            <Grid item xs={6}>
              <SequenceNumberField
                label={`FOC (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`}
                id={`${fuelType.abbreviation}-inputvalue`}
                size='small'
                value={value ?? ''}
                onChange={(e) => handleValueChange(e, fuelType)}
                inputProps={{ step: 1 }}
                onClick={(event) => event.stopPropagation()}
              />
            </Grid>
          </Grid>
        </MenuItem>
      );
    });
  });

  return menuItems;
}

// return the selected fuel types
export const getSelectedFuelTypes = (selectedFuelTypes: IFuelDto[]) =>
  selectedFuelTypes.toSorted((a, b) =>
    (a.fuelType ?? '').localeCompare(b.fuelType ?? '')
  );

// this function is used to render the input fuel types in the select component
// shows the "chips" with values of the fuel types
export const FuelTypeSelectRender = (
  fuelTypes: IFuelType[],
  selected: IFuelDto[],
  handleDelete: Function
) => (
  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    {selected.map((value, ix) => {
      const fuelType = fuelTypes.find((x) => x.name === value.fuelType);
      let abbreviation = fuelType?.abbreviation ?? '';
      if (abbreviation === 'Other') {
        abbreviation = fuelType?.classificationName + ' ' + abbreviation;
      }

      if (abbreviation === '') {
        return null;
      }

      const fuelConsumption = Number.isNaN(value.fuelConsumption)
        ? 0
        : formatNumber(value.fuelConsumption);

      return (
        <Chip
          key={abbreviation + ix}
          label={`${abbreviation}: ${fuelConsumption}`}
          onDelete={() => handleDelete(value)}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
        />
      );
    })}
  </Box>
);

const handleDeleteFuel = (
  params: GridRenderCellParams<ISequence>,
  fuel: IFuelDto
) => {
  const { api, row } = params;
  const newFuelTypes = row.fuelType?.filter((x) => x !== fuel);
  const updatedRow = updateProperty(row, 'fuelType', newFuelTypes);
  api.updateRows([updatedRow]);
};

export const fuelSelectProps = (
  params: GridRenderCellParams<ISequence>,
  fuelTypes: IFuelType[]
) => {
  const deleteFuelCallback = (fuel: IFuelDto) => handleDeleteFuel(params, fuel);
  const selectProps: SelectProps<IFuelDto[]> = {
    multiple: true,
    open: false,
    renderValue: (value) =>
      FuelTypeSelectRender(fuelTypes, value, deleteFuelCallback),
  };
  return selectProps as SelectProps<unknown>;
};
