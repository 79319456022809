import { Box, IconButton, Link, SxProps, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridColDef, GridPinnedPosition } from '@mui/x-data-grid-pro';
import { SearchWidget } from 'shared/components/esri-map/utils/searchWidget';
import {
  DataGridTable,
  GridColumn,
  GridRAGColumnLink,
  nameof,
} from 'shared/components/datagrid/DataGrid.component';
import { useFilter } from 'shared/components/rag-filter/filter.hook';
import { RAGFilter } from 'shared/components/rag-filter/grid-filter.component';
import { IFleetKPITableData } from '../models/fleet-kpis.model';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'router/router';
import { useGetUser } from 'shared/services/user.service';
import { userHasPermission } from 'shared/utils/permissions-utils';
import { PermissionModule } from 'shared/models/user.model';
import { constants } from 'routes/dashboard/dashboard.route';
import { ApolloError } from '@apollo/client';
import { formatLargeNumber, isNumeric } from 'shared/utils/float-utils';
import { ghgGetStandardKvpColumn } from 'routes/environmental-monitor/components/vessel-detail/voyage-comparison/voyage-comparison.component';
import { useFeatureToggleContext } from 'shared/components/contexts/featureToggleProvider.component';
import { FEATURE_FLAG } from 'shared/constants/feature-flag';
import { GridCustomHeaderClass } from 'shared/components/datagrid';

function searchIcon(vesselName: string, searchId: string) {
  SearchWidget.search(vesselName, true, searchId);
}

function getColumnsDefinition(
  navigate: any,
  featureFlags: {
    [key: string]: boolean;
  }
): GridColDef[] {
  const columns = nameof<IFleetKPITableData>; // get the properties from the model
  const goToEMVesselPage = (row: any) => {
    navigate(`${ROUTES.environmentalMonitor.path}/vessels/${row.id}`);
  };

  const goToVPMVesselPage = (row: any) => {
    navigate(`${ROUTES.vesselPerformance.path}/${row.id}/${row.vesselImo}`);
  };

  const goToVDMVesselPage = (row: any) => {
    navigate(ROUTES.vesselDataSummary.path.replace(':id', `${row.vesselImo}`));
  };

  const ghgMrvFeatureFlagEnabled =
    featureFlags[FEATURE_FLAG.DASHBOARD_VESSELS_KPIS_COMPARISON_GRID_GHG_MRV];

  const fuelEuFeatureFlagEnabled =
    featureFlags[FEATURE_FLAG.DASHBOARD_VESSELS_KPIS_COMPARISON_GRID_FUEL_EU];

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useGetUser();
  const userPermissions = data?.permissions;
  const BIG_CELL_WIDTH = 180;

  const defaultColumns: any = [
    {
      field: columns('VesselName'),
      headerName: 'Vessel Name',
      pinPosition: GridPinnedPosition.left,
      flex: 1,
      minWidth: BIG_CELL_WIDTH,
      renderCell: (params: any) => (
        <>
          {params.value}
          <IconButton onClick={() => searchIcon(params.value, params.id)}>
            <VisibilityIcon fontSize='small' />
          </IconButton>
        </>
      ),
    },
    {
      field: columns('SisterClass'),
      headerName: 'Sister Class',
      minWidth: BIG_CELL_WIDTH,
      headerClassName: GridCustomHeaderClass.BorderRight,
    },
  ];

  const columnsForEM = [
    GridRAGColumnLink(
      {
        field: columns('CII'),
        type: 'string',
        headerName: 'CII \nRating',
      },
      goToEMVesselPage
    ),
    GridRAGColumnLink(
      { field: columns('EEOI'), headerName: 'EEOI (%)' },
      goToEMVesselPage
    ),
    GridRAGColumnLink(
      {
        field: columns('EUA'),
        headerName: 'EUA \nAgainst Planned \n(%)',
        visibility: (dataRow: any) => {
          if (!dataRow) return false;
          const row = dataRow as IFleetKPITableData;
          const rawValue = row.vesselEuaPlannedValue;
          const value = isNumeric(rawValue) ? Number(rawValue) : 0;
          return value > 0;
        },
      },
      goToEMVesselPage
    ),
  ];

  const columnsForGhgMrv = [
    GridColumn({
      field: columns('ghgMrv.ghgByYear'),
      headerName: 'GHGmrv \n(tCO₂eq, TtW)',
      type: 'any',
      align: 'center',
      headerAlign: 'center',
      minWidth: 135,
      valueField: columns('ghgMrv.ghgByYear'),
      valueFormatter: (params) => ghgGetStandardKvpColumn(params),
      renderCell: (params: any) => {
        return (
          <Link onClick={() => goToEMVesselPage(params.row)}>
            <span className='MuiDataGrid-cellContent pointer'>
              {params.formattedValue}
            </span>
          </Link>
        );
      },
      renderHeader() {
        const styleProperties: SxProps = {
          fontSize: 'inherit',
          textAlign: 'center',
        };
        return (
          <Box className='MuiDataGrid-columnHeaderTitle'>
            <Typography key={1} sx={styleProperties}>
              GHGmrv
            </Typography>
            <Typography key={2} sx={styleProperties}>
              (tCO₂eq, TtW)
            </Typography>
          </Box>
        );
      },
    }),
  ];

  const columnsForVPM = [
    GridRAGColumnLink(
      {
        field: 'FoulingResistance',
        headerName: 'Fouling \nResistance \n(%)',
      },
      goToVPMVesselPage
    ),
    GridRAGColumnLink(
      {
        field: columns('DeltaPower'),
        headerName: 'Excess \nPower \n(%)',
      },
      goToVPMVesselPage
    ),
    GridRAGColumnLink(
      { field: 'DeltaSpeed', headerName: 'Speed \nLoss \n(%)' },
      goToVPMVesselPage
    ),
    GridRAGColumnLink(
      {
        field: columns('MainEngine'),
        headerName: 'Main \nEngine \nSFOC (%)',
      },
      goToVPMVesselPage
    ),
  ];

  const columnsForVDM = [
    GridRAGColumnLink(
      {
        field: columns('PassedScore'),
        headerName: 'Overall Passed \nScore \n(%)',
      },
      goToVDMVesselPage
    ),
    {
      field: columns('FlaggedReports'),
      headerName: 'Flagged',
      type: 'number',
      renderCell: (params: any) => (
        <Link onClick={() => goToVDMVesselPage(params.row)}>
          {params.value}
        </Link>
      ),
    },
  ];
  const columnsForFuelEu = [
    GridRAGColumnLink(
      {
        field: columns('GhgIntensity'),
        type: 'string',
        noDecimals: 2,
        headerName: 'GHG Intensity \n(gCO₂eq/MJ, WtW)',
      },
      goToEMVesselPage
    ),
    GridColumn({
      field: columns('FuelEUPenalty'),
      headerName: 'FuelEU \nPenalty (€)',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      minWidth: 135,
      renderCell: (params: any) => {
        const rawValue = params.row.FuelEUPenalty;
        const value = formatLargeNumber(rawValue);
        return (
          <Link onClick={() => goToEMVesselPage(params.row)}>
            <span className='MuiDataGrid-cellContent pointer'>{value}</span>
          </Link>
        );
      },
      noDecimals: 2,
    }),
  ];
  if (
    userHasPermission([PermissionModule.EnvironmentalMonitor], userPermissions)
  ) {
    defaultColumns.push(...columnsForEM);
    if (fuelEuFeatureFlagEnabled) defaultColumns.push(...columnsForFuelEu);
  }
  if (ghgMrvFeatureFlagEnabled) defaultColumns.push(...columnsForGhgMrv);
  if (
    userHasPermission(
      [PermissionModule.VesselPerformanceMonitor],
      userPermissions
    )
  )
    defaultColumns.push(...columnsForVPM);
  if (userHasPermission([PermissionModule.VesselDataMonitor], userPermissions))
    defaultColumns.push(...columnsForVDM);

  return defaultColumns;
}

export const ActiveVesselsGridTable = ({
  data,
  loading,
  error,
}: {
  data: IFleetKPITableData[];
  loading: boolean;
  error: ApolloError | undefined;
}) => {
  const navigate = useNavigate();
  const { featureFlags } = useFeatureToggleContext();
  const columns = getColumnsDefinition(navigate, featureFlags);
  const extractKeysForFiltering = ({
    CII,
    EEOI,
    EUA,
    FoulingResistance,
    DeltaPower,
    DeltaSpeed,
    MainEngine,
    PassedScore,
  }: IFleetKPITableData) => {
    const originData: Record<string, unknown> = {
      CII,
      EEOI,
      EUA,
      FoulingResistance,
      DeltaPower,
      DeltaSpeed,
      MainEngine,
      PassedScore,
    };

    return Object.keys(originData).reduce((data, key) => {
      if (columns.find((column) => column.field === key)) {
        return { ...data, [key]: originData[key] };
      }

      return data;
    }, {});
  };

  const filterResult = useFilter<IFleetKPITableData>(
    data ?? [],
    extractKeysForFiltering
  );

  return (
    <>
      <RAGFilter
        activeFilters={filterResult.activeFilters}
        handleToggle={filterResult.handleFilter}
      ></RAGFilter>
      <DataGridTable
        name={constants.SECTION_ACTIVE_VESSELS_TABLE_ID}
        rows={filterResult.filteredData}
        columns={columns}
        loading={loading}
        error={error}
        initialState={{
          sorting: { sortModel: [{ field: 'VesselName', sort: 'asc' }] },
        }}
      ></DataGridTable>
    </>
  );
};
