import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  SxProps,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGridTable, GridColumn, nameof } from 'shared/components/datagrid';
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { TEXT } from 'shared/constants/text';
import { ValidatedTextField } from 'shared/components/inputs/input-fields';
import { IFuelType } from 'routes/environmental-monitor/models/fuelType.model';
import { useGetFuelTypes } from 'routes/environmental-monitor/services/fueltypes.service';
import {
  IFuelDto,
  type ISequence,
} from '../../../models/plannedItinerary.model';
import { useEffect, useState, useRef } from 'react';
import { theme } from 'styles/theme';
import { SecondaryButton } from 'shared/components/secondary-button/secondary-button.component';
import { groupBy } from 'shared/utils/display-utils';

import { StyleButton } from '../modal/sequence.component';
import { updateProperty } from '../modal/sequence.config';
import { IConsumer } from 'routes/environmental-monitor/models/consumer.model';
import { useParams } from 'react-router-dom';
import { useGetVesselById } from 'routes/environmental-monitor/services/vessel.service';

const gridStyle: SxProps = {
  my: 1,
  '& .MuiGrid-item': {
    pt: 0,
  },
};

const emptyFuelType: IFuelType = {
  abbreviation: '',
  name: '',
  enumValue: 0,
  classificationName: '',
};

export interface ISequenceFuelType {
  id: number;
  fuelType: IFuelType;
  value?: number;
  consumerType?: string | undefined;
  fuelSlip?: number;
}

/**
 * This function is used to render the custom "fuel types" dialog for the vessel planned itinerary
 */
export function FuelTypesDialog(
  props: Readonly<{
    fuelData: IFuelDto[] | undefined;
    open: boolean;
    handleClose: () => void;
    handleSave: (SequenceFuelType: IFuelDto[]) => void;
  }>
) {
  const { data, loading } = useGetFuelTypes();
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>([]);
  const [engines, setEngines] = useState<IConsumer[]>([]);

  const { id } = useParams();
  const vessel = useGetVesselById(id);

  useEffect(() => {
    if (loading === false && data) {
      setFuelTypes(data);
    }

    if (vessel.loading === false && vessel.data) {
      setEngines(vessel.data.engines);
    }

    if (loading === false && data && vessel.loading === false && vessel.data) {
      setSequenceFuelTypes(
        props.fuelData?.map((x, ix) => ({
          id: ix + 1,
          fuelType: data.find((y) => y.name === x.fuelType) ?? emptyFuelType,
          value: x.fuelConsumption ?? 0,
          consumerType: x.consumerId,
        })) ?? []
      );
    }
  }, [loading, vessel.loading, props.fuelData]);

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  //
  // main fuel type sequences for the grid
  const [sequenceFuelTypes, setSequenceFuelTypes] = useState<
    ISequenceFuelType[]
  >([]);

  const apiRef = useGridApiRef();
  const gridInputData = () =>
    apiRef.current?.getSortedRows() as ISequenceFuelType[];
  const newClss: HandleFuelTypes = new HandleFuelTypes(fuelTypes, engines);

  const updateGridData = (newData: ISequenceFuelType[]) => {
    newData.forEach((row, ix) => {
      row.id = ix + 1;
    });
    setSequenceFuelTypes(newData);
  };

  const addNewFuelType = () => {
    const emptyRecord = {
      id: sequenceFuelTypes.length + 1,
      fuelType: emptyFuelType,
      value: 0,
    };
    const gridData = gridInputData();
    updateGridData([...gridData, emptyRecord]);
  };

  /** Delete Fuel Type by ID */
  const deleteFuelType = (id: number) => {
    setSequenceFuelTypes((prev) => prev.filter((fuel) => fuel.id !== id));
  };

  const saveResults = () => {
    const gridInputData =
      apiRef?.current?.getSortedRows() as ISequenceFuelType[];
    const results: IFuelDto[] = gridInputData.map((x) => {
      return {
        id: x.id,
        fuelType: x.fuelType.name,
        fuelConsumption: x.value ?? 0,
        consumerId: x.consumerType ?? null,
      };
    });

    props.handleSave(results);
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll={'paper'}
      fullWidth
      maxWidth='lg'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      PaperProps={{ sx: { borderRadius: '8px', border: '1px solid #555' } }}
    >
      <Box
        sx={{
          backgroundColor: theme.background.component,
        }}
      >
        <DialogTitle id='scroll-dialog-title'>Fuel Consumption</DialogTitle>
        <DialogContent
          dividers={true}
          sx={{ backgroundColor: theme.background.component, height: '500px' }}
        >
          <Box
            id='scroll-dialog-description'
            component={'section'}
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <SecondaryButton
                variant='outlined'
                sx={{ mr: 1.5 }}
                onClick={addNewFuelType}
              >
                {'+ Add Fuel Type'}
              </SecondaryButton>
            </Box>

            <DataGridTable
              apiRef={apiRef}
              sx={{ border: 'none' }}
              rows={sequenceFuelTypes}
              columns={getColumnsDefinitionFuelType(newClss, deleteFuelType)}
              rowStyle='rounded'
              getRowHeight={() => 'auto'}
              columnHeaderHeight={0}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ mr: 2 }}>
          <SecondaryButton
            onClick={props.handleClose}
            variant='outlined'
            sx={{ minWidth: '80px' }}
          >
            Cancel
          </SecondaryButton>
          <StyleButton onClick={saveResults}>Save</StyleButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

function getColumnsDefinitionFuelType(
  cssl: HandleFuelTypes,
  deleteFuelType: (id: number) => void
): GridColDef[] {
  const columns = nameof<IFuelType>;

  const columnDefinition = [
    GridColumn({
      field: 'sequenceRow',
      headerName: 'Status',
      headerAlign: 'center',
      type: 'string',
      valueField: columns('abbreviation'),
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequenceFuelType>) => {
        return cssl.SequenceFuelTypeRow(params);
      },
    }),
    GridColumn({
      maxWidth: 50,
      field: 'delete',
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequence>) => {
        const noRows = params.api.getRowsCount();
        if (noRows === 1) {
          return null;
        }

        return (
          <IconButton
            aria-label='delete'
            onClick={() => deleteFuelType(params.row.id)}
          >
            <CancelIcon />
          </IconButton>
        );
      },
    }),
  ];

  return columnDefinition;
}

class HandleFuelTypes {
  fuelTypes: IFuelType[] = [];
  fuelTypesGrouped: { [key: string]: IFuelType[] };
  consumers: IConsumer[] = [];

  constructor(fuelTypes: IFuelType[], consumers: IConsumer[] = []) {
    this.fuelTypes = fuelTypes;
    this.fuelTypesGrouped = groupBy(fuelTypes, (x) => x.classificationName);
    this.consumers = consumers;
  }

  /**
   * this function is used to update the row sequence
   */
  updateRowSequence = (
    params: GridRenderCellParams<ISequenceFuelType>,
    field: keyof ISequenceFuelType,
    value: any
  ) => {
    const { api, row } = params;

    // fueltype uses a complex object
    if (field === 'fuelType' && typeof value === 'string') {
      const fuelType = this.fuelTypes.find((x) => x.abbreviation === value);
      const updatedRow = updateProperty(row, field, fuelType);
      api.updateRows([updatedRow]);
      return;
    }

    const updatedRow = updateProperty(row, field, value);
    api.updateRows([updatedRow]);
  };

  /**
   * This function is used to get the menu items for the fuel types
   * @returns ReactNode array
   */
  get fuelTypeMenuItems() {
    return Object.entries(this.fuelTypesGrouped).map(([key, value]) => {
      const Menus = value.map((fuelType) => {
        return (
          <MenuItem key={fuelType.abbreviation} value={fuelType.abbreviation}>
            {fuelType.abbreviation}
          </MenuItem>
        );
      });
      const menuHeader = (
        <ListSubheader
          key={key}
          sx={{
            backgroundColor: 'transparent',
            color: '#AAA',
            fontSize: 'medium',
          }}
        >
          {key}
        </ListSubheader>
      );
      return [menuHeader, Menus];
    });
  }

  /**
   * This function is used to get the menu items for the consumers
   * @returns ReactNode array
   */
  get consumersMenuItems() {
    return this.consumers.map((consumer) => {
      return (
        <MenuItem key={consumer.id} value={consumer.id}>
          {consumer.name}
        </MenuItem>
      );
    });
  }

  /**
   * This function is used to render the fuel type row
   * @param params GridRenderCellParams<ISequenceFuelType> - The parameters of the row, this is coming from the data grid
   * @returns ReactNode - The fuel type row sequence
   */
  SequenceFuelTypeRow(params: GridRenderCellParams<ISequenceFuelType>) {
    // Check if the fuel type is LNG
    const showFuelSlip = params.row.fuelType?.abbreviation === 'LNG';
    const UpdateRowField = (field: keyof ISequenceFuelType, value: any) =>
      this.updateRowSequence(params, field, value);

    return (
      <Grid container spacing={2} sx={gridStyle}>
        <Grid item xs={3}>
          <ValidatedTextField
            label={`Type #${params.row.id}`}
            select
            value={params.row.fuelType?.abbreviation ?? ''}
            required
            onChange={(e) => UpdateRowField('fuelType', e.target.value)}
          >
            {this.fuelTypeMenuItems}
          </ValidatedTextField>
        </Grid>

        <Grid item xs={2}>
          <ValidatedTextField
            label={'Classification'}
            value={params.row.fuelType.classificationName ?? ''}
            itemID={params.row.fuelType?.abbreviation}
          />
        </Grid>

        <Grid item xs={2}>
          <ValidatedTextField
            type='number'
            label={`Amount (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`}
            required
            value={params.row.value ?? ''}
            itemID={params.row.fuelType?.abbreviation}
            onChange={(e) =>
              UpdateRowField('value', parseFloat(e.target.value))
            }
          ></ValidatedTextField>
        </Grid>

        {showFuelSlip && (
          <Grid item xs={3}>
            <ValidatedTextField
              label={'Consumer'}
              required
              select
              value={params.row.consumerType}
              itemID={params.row.fuelType?.abbreviation}
              onChange={(e) => UpdateRowField('consumerType', e.target.value)}
            >
              {this.consumersMenuItems}
            </ValidatedTextField>
          </Grid>
        )}
      </Grid>
    );
  }
}
