import { Box, Grid, IconButton, SxProps } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  GridColumn,
  GridCustomHeaderClass,
  nameof,
} from 'shared/components/datagrid';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { StatusStyle } from 'routes/alerts/components/alert-table/alert-styles';
import { TEXT } from 'shared/constants/text';
import { PortSelectionInput } from '../port-selection/port-selection.component';
import {
  EmptyOption,
  ListEnum,
  ValidatedTextField,
} from 'shared/components/inputs/input-fields';
import { IFuelType } from 'routes/environmental-monitor/models/fuelType.model';
import { useGetFuelTypes } from 'routes/environmental-monitor/services/fueltypes.service';
import {
  IFuelDto,
  type ISequence,
  LoadingConditionTypes,
  SequenceTypes,
} from '../../../models/plannedItinerary.model';
import { EMPTY_PORT_DATA, PortData } from 'shared/utils/portpolygons-util';
import { useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { updateSequenceField } from '../sequence-rules/sequence-rule';
import { formatNumber } from 'shared/utils/float-utils';
import { fuelSelectProps, ListFuelTypes } from '../fuel-types/renders';
import { FuelTypesDialog } from '../fuel-types/dialog';

const gridStyle: SxProps = {
  my: 1,
  '& .MuiGrid-item': {
    pt: 0,
  },
};

// Typescript Function that updates the field of the row
// and calls the api to update the row
export function updateProperty<T>(
  obj: T,
  propertyName: keyof T,
  value: any
): T {
  return { ...obj, [propertyName]: value };
}

export const SequenceRow = (params: GridRenderCellParams<ISequence>) => {
  const { data, loading } = useGetFuelTypes();
  const [fuelTypes, setFuelTypes] = useState<IFuelType[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loading === false && data) {
      setFuelTypes(data);
    }
  }, [loading]);

  const UpdateRowField = (field: keyof ISequence, value: any) =>
    updateSequenceField(params, field, value);

  const getSequenceTypes = (rowId: number) => {
    const sequenceTypesFirstRow = Object.fromEntries(
      Object.entries(SequenceTypes).filter(
        ([_, value]) =>
          ![
            SequenceTypes.InPortLoading,
            SequenceTypes.InPortDischarge,
          ].includes(value)
      )
    );

    if (rowId === 1) {
      return sequenceTypesFirstRow;
    }

    return SequenceTypes;
  };

  // If the sequence is not defined, we show an empty sequence row
  if (params.row.sequence === EmptyOption.Undefined) {
    return (
      <Grid container spacing={2} sx={gridStyle}>
        <Grid item xs={2}>
          <ValidatedTextField
            label={`Sequence ${params.row.id}`}
            select
            value={params.row.sequence ?? ''}
            required
            onChange={(e) => UpdateRowField('sequence', e.target.value)}
          >
            {ListEnum(getSequenceTypes(params.row.id))}
          </ValidatedTextField>
        </Grid>
      </Grid>
    );
  }

  const showPortInput = !(
    params.row.sequence === SequenceTypes.AtSea ||
    params.row.sequence === SequenceTypes.Drifting ||
    params.row.sequence === SequenceTypes.Maneuvering
  );

  const showDurationInput = !(
    params.row.sequence === SequenceTypes.AtSea ||
    params.row.sequence === SequenceTypes.Maneuvering
  );

  const showSpeedAndDistance =
    params.row.sequence === SequenceTypes.AtSea ||
    params.row.sequence === SequenceTypes.Maneuvering;

  const fuelTypeValues = params.row.fuelType?.filter((x) => x?.id >= 0) ?? [];

  const handleFuelTypeModalOpen = () => setOpen(true);
  const handleFuelTypeModalClose = () => setOpen(false);
  const handleFuelTypeModalSave = (fuelTypes: IFuelDto[]) => {
    UpdateRowField('fuelType', fuelTypes);
    handleFuelTypeModalClose();
  };

  return (
    <Grid container spacing={2} sx={gridStyle}>
      <Grid item xs={2}>
        <ValidatedTextField
          label={`Sequence ${params.row.id}`}
          select
          required
          value={params.row.sequence ?? ''}
          sx={showErrorBorder(params.row.sequenceFailingField, 'sequence')}
          onChange={(e) => UpdateRowField('sequence', e.target.value)}
        >
          {ListEnum(getSequenceTypes(params.row.id))}
        </ValidatedTextField>
      </Grid>

      <Grid item xs>
        <ValidatedTextField
          label={'Loading Condition'}
          select
          required
          value={params.row.loadingCondition ?? ''}
          sx={showErrorBorder(
            params.row.sequenceFailingField,
            'loadingCondition'
          )}
          onChange={(e) => UpdateRowField('loadingCondition', e.target.value)}
          itemID={params.row.sequence}
        >
          {ListEnum(LoadingConditionTypes)}
        </ValidatedTextField>
      </Grid>

      {showSpeedAndDistance && (
        <>
          <Grid item xs>
            <ValidatedTextField
              label={`Distance (${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`}
              required
              value={params.row.distance ?? ''}
              onChange={(e) => UpdateRowField('distance', e.target.value)}
              fieldType='number'
              itemID={params.row.sequence}
            />
          </Grid>

          <Grid item xs>
            <ValidatedTextField
              label={`Speed (${TEXT.UNIT_MEASUREMENT.KNOTS})`}
              required
              value={params.row.speed ?? ''}
              onChange={(e) => UpdateRowField('speed', e.target.value)}
              fieldType='number'
              itemID={params.row.sequence}
            />
          </Grid>
        </>
      )}

      {showPortInput && (
        <Grid item xs>
          <PortSelectionInput
            onMenuChange={(newValue) => UpdateRowField('port', newValue)}
            required
            value={(params.row.port as PortData) ?? EMPTY_PORT_DATA}
            label={'Port'}
            itemID={params.row.sequence}
          />
        </Grid>
      )}

      {showDurationInput && (
        <Grid item xs>
          <ValidatedTextField
            label={`Duration (${TEXT.UNIT_MEASUREMENT.HOUR})`}
            required
            value={params.row.duration ?? ''}
            onChange={(e) => UpdateRowField('duration', e.target.value)}
            fieldType='number'
            inputProps={{ min: 0, step: 1 }}
            itemID={params.row.sequence}
          />
        </Grid>
      )}

      <Grid item xs={3}>
        <ValidatedTextField
          label={`Fuel Type (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`}
          required
          select
          itemID={params.row.sequence}
          value={fuelTypeValues}
          SelectProps={fuelSelectProps(params, fuelTypes)}
          sx={showErrorBorder(params.row.sequenceFailingField, 'fuelType')}
          onClick={handleFuelTypeModalOpen}
        >
          {ListFuelTypes(fuelTypes, params.row.fuelType ?? [], UpdateRowField)}
        </ValidatedTextField>
        <FuelTypesDialog
          fuelData={fuelTypeValues}
          open={open}
          handleClose={handleFuelTypeModalClose}
          handleSave={handleFuelTypeModalSave}
        />
      </Grid>

      <Grid item xs>
        <ValidatedTextField
          label={`Cargo Quantity (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`}
          required
          value={params.row.cargoQuantity ?? ''}
          sx={showErrorBorder(params.row.sequenceFailingField, 'cargoQuantity')}
          onChange={(e) => UpdateRowField('cargoQuantity', e.target.value)}
          fieldType='number'
          itemID={params.row.sequence}
        />
      </Grid>
    </Grid>
  );
};

function showErrorBorder(
  failingField: keyof ISequence | undefined,
  fieldName: keyof ISequence
) {
  return failingField === fieldName
    ? { border: `1px solid ${theme.palette.error.main}`, borderRadius: '5px' }
    : {};
}

export function getColumnsDefinition(
  handleDeleteClick: (id: string | number) => void
): GridColDef[] {
  const columns = nameof<ISequence>;

  const columnDefinition = [
    GridColumn({
      field: 'selectRow',
      align: 'left',
      valueField: 'isValid',
      headerName: '',
      cellClassName: GridCustomHeaderClass.NoGutters,
      headerClassName: GridCustomHeaderClass.NoGutters,
      maxWidth: 10,
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequence>) => {
        return (
          <Box
            sx={{
              ...StatusStyle(
                false,
                (params.row.sequenceFailingField?.length ?? 0) > 0
              ),
              height: '100%',
            }}
          ></Box>
        );
      },
    }),
    GridColumn({
      field: 'sequenceRow',
      headerName: 'Status',
      headerAlign: 'center',
      type: 'string',
      valueField: columns('sequence'),
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequence>) => {
        return SequenceRow(params);
      },
    }),

    GridColumn({
      maxWidth: 50,
      field: 'delete',
      visibility: 'screen-only',
      renderCell: (params: GridRenderCellParams<ISequence>) => {
        const noRows = params.api.getRowsCount();
        if (noRows === 1) {
          return null;
        }

        return (
          <IconButton
            aria-label='delete'
            onClick={() => handleDeleteClick(params.row.id)}
          >
            <CancelIcon />
          </IconButton>
        );
      },
    }),

    GridColumn({
      field: columns('sequence'),
      headerName: 'Sequence',
      headerAlign: 'center',
      type: 'string',
      visibility: 'export-only',
      valueField: columns('sequence'),
    }),
    GridColumn({
      field: columns('loadingCondition'),
      headerAlign: 'center',
      type: 'string',
      visibility: 'export-only',
      valueField: columns('loadingCondition'),
    }),
    GridColumn({
      field: columns('distance'),
      headerName: `Distance (${TEXT.UNIT_MEASUREMENT.NAUTICAL_MILE})`,
      visibility: 'export-only',
      valueField: columns('distance'),
    }),
    GridColumn({
      field: columns('speed'),
      headerName: `Speed (${TEXT.UNIT_MEASUREMENT.KNOTS})`,
      visibility: 'export-only',
      valueField: columns('speed'),
    }),
    GridColumn({
      field: columns('port.Port_Name'),
      headerName: 'Port',
      type: 'string',
      visibility: 'export-only',
      valueField: columns('port.Port_Name'),
    }),
    GridColumn({
      field: columns('duration'),
      headerName: `Duration (${TEXT.UNIT_MEASUREMENT.HOUR})`,
      visibility: 'export-only',
      valueField: columns('duration'),
    }),
    GridColumn({
      field: columns('fuelType'),
      headerName: `Fuel Type (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES_PER_DAY})`,
      type: 'string',
      headerAlign: 'right',
      align: 'right',
      visibility: 'export-only',
      cellClassName: GridCustomHeaderClass.PreLine,
      valueFormatter: (params: GridValueFormatterParams<IFuelDto[]>) => {
        const fuelTypes = params.value;
        const fueltypesAsString = fuelTypes
          ?.map((x) => `${x.fuelType}: ${formatNumber(x.fuelConsumption)}`)
          .join('\n');
        return fueltypesAsString;
      },
    }),
    GridColumn({
      field: columns('cargoQuantity'),
      headerName: `Cargo Quantity (${TEXT.UNIT_MEASUREMENT.METRIC_TONNES})`,
      visibility: 'export-only',
      valueField: columns('cargoQuantity'),
    }),
  ];

  return columnDefinition;
}
export { ISequence };
