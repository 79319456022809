import { AreaDefinition } from 'shared/components/mui-chart/background/draw-background.component';
import { UTCDate } from 'shared/utils/date-utc-helper';
import { groupBy } from 'shared/utils/display-utils';
import { theme } from 'styles/theme';
import { DataPoint, DataPointWithUTCDate } from '../models/fleet.model';

/**
 * valid values for the dropdown in the UI.
 *
 *
 * "0" : Current year.\
 * "3" : Current year + 3 years.\
 * "5" : Current year + 5 years.\
 * "-1" : Current year - 2050.
 */
export type rangeValues = '0' | '3' | '5' | '-1';

/**
 * This class is used to create the dataset for the FuelEU trend chart.
 *
 * It receives the data points and the range of years to be displayed in the chart.
 *
 * It also creates the dataset based on the range of years selected.
 */
export class TrendChartDataset {
  private readonly noYears: rangeValues;
  private readonly dataPoints: DataPoint[] = [];
  private readonly currentYear: number =
    new UTCDate().date?.getUTCFullYear() ?? 0;
  private dataset: DataPoint[] = [];

  constructor(
    sourceDatapoints: DataPointWithUTCDate[] | undefined,
    noYears: rangeValues = '0'
  ) {
    this.noYears = noYears;
    if (sourceDatapoints === undefined) return;
    this.dataPoints = this.GetDataset(sourceDatapoints);
    this.setupDataset();
  }

  /**
   * This returns the data points based only on the original data source.
   *
   * It doesn't include any forecast data points.
   * @returns DataPoint[]
   */
  get rangeDataset() {
    return this.dataPoints;
  }

  /**
   * This returns the full dataset, including the points based in the original data source, and the forecast data points.
   * @returns DataPoint[]
   */
  get fullDataset() {
    return this.dataset;
  }

  /**
   * Convert the DataPointWithUTCDate[] to DataPoint[].
   * @param sourceDatapoint DataPointWithUTCDate[]
   * @returns DataPoint[]
   */
  GetDataset(sourceDatapoint: DataPointWithUTCDate[]): DataPoint[] {
    return sourceDatapoint.map((p) => {
      return {
        date: p.date.date!,
        isFutureProjection: p.isFutureProjection ?? false,
        intensity: p.intensity ?? 0, // Provide a default value of 0 for intensity
        intensityTarget: p.intensityTarget ?? 0,
        complianceBalance: p.complianceBalance ?? 0,
        fuelEUPenalty: p.fuelEUPenalty ?? 0,
        fossilFuel: p.fossilFuel,
        bioFuel: p.bioFuel,
        eFuel: p.eFuel,
      };
    });
  }

  /**
   * Fill the missing months in the dataset, depending on the range of years selected.
   * @param data DataPoint[]
   * @returns DataPoint[]
   */
  fillMissingMonths(data: DataPoint[]) {
    const lastIntensityValues = data.at(-1);
    const missingMonths: number[] = [];
    Array.from({ length: 12 }).forEach((_, ix) => {
      if (data.find((item) => item.date?.getUTCMonth() === ix)) return;
      missingMonths.push(ix);
    });

    const dataset = [...data];

    missingMonths.forEach((month) => {
      dataset.push({
        date: new Date(new Date().getUTCFullYear(), month, 1),
        isFutureProjection: true,
        intensity: lastIntensityValues?.intensity ?? 0,
        intensityTarget: lastIntensityValues?.intensityTarget ?? 0,
        complianceBalance: 0,
        fuelEUPenalty: 0,
        fossilFuel: 0,
        bioFuel: 0,
        eFuel: 0,
      });
    });

    return dataset;
  }

  /**
   * Get the data points for the current year.
   * @returns DataPoint[]
   */
  getCurrentYearData() {
    const originalDataset = this.dataPoints;

    if (originalDataset.length === 0) return [];
    const currentYearMonths = originalDataset.filter(
      (item) =>
        item?.date &&
        item.date.getUTCFullYear() === this.currentYear &&
        item.isFutureProjection === false
    );

    // If the range of years is set to -1, we only return the last month value of the current year
    if (this.noYears === '-1') {
      return currentYearMonths.slice(-1);
    }

    const dataset = [...currentYearMonths];
    return dataset;
  }

  /**
   * Get the data points for the forecast years.
   * @returns DataPoint[]
   */
  forecastYears() {
    const originalDataset = this.dataPoints;
    if (originalDataset.length === 0) return [];

    const lastValidDataPoint = this.getLastValidDataPoint();

    const forecastYears = originalDataset.filter(
      (item) => item.date && item.date.getUTCFullYear() > this.currentYear
    );
    const yearsGrouped = groupBy(
      forecastYears,
      (x) => x.date?.getUTCFullYear().toString() ?? '0'
    );

    const dataset: DataPoint[] = [];
    Object.entries(yearsGrouped).forEach(([_, value]) => {
      const sortedValues = value.toSorted((a, b) => {
        const dateA = a?.date?.getTime() ?? 0;
        const dateB = b?.date?.getTime() ?? 0;
        return dateA - dateB;
      });

      const lastValue = sortedValues.at(-1);
      if (lastValue?.date === undefined) return;

      // we are using the last valid data point to fill the intensity values, since we are mixing the forecast data with the real data
      const result: DataPoint = {
        date: lastValue?.date,
        isFutureProjection: true,
        intensity: lastValidDataPoint?.intensity ?? 0,
        intensityTarget: lastValue?.intensityTarget,
        complianceBalance: lastValue?.complianceBalance,
        fuelEUPenalty: lastValue?.fuelEUPenalty,
        fossilFuel: lastValue?.fossilFuel,
        bioFuel: lastValue?.bioFuel,
        eFuel: lastValue?.eFuel,
      };
      dataset.push(result);
    });
    return dataset;
  }

  // Setup the dataset based on the range of years selected
  setupDataset() {
    const currentYearData = this.getCurrentYearData();
    const cleanedData =
      this.noYears === '-1'
        ? currentYearData
        : this.fillMissingMonths(currentYearData);
    const forecastData = this.forecastYears();
    this.dataset = [...cleanedData, ...forecastData];
  }

  /**
   * Get the band areas for the chart.
   * Areas are updated based on the dataPoints.
   * It uses the target intensity values to draw the bands.
   * @returns AreaDefinition[]
   */
  getBandAreas() {
    const firstItem = this.dataPoints[0];
    let intensityTarget = firstItem?.intensityTarget ?? 0;
    const areas: Array<AreaDefinition> = [];
    if (firstItem?.date) {
      areas.push({
        x: firstItem.date.getTime(),
        y: Math.round(firstItem.intensityTarget * 100) / 100,
        color: theme.colors?.aRating,
      });
    }
    this.dataPoints.forEach((item) => {
      if (item.intensityTarget !== intensityTarget) {
        if (item.date) {
          areas.push({
            x: item.date.getTime(),
            y: Math.round(item.intensityTarget * 100) / 100,
            color: theme.colors?.aRating,
          });
        }
        intensityTarget = item.intensityTarget;
      }
    });
    return areas;
  }

  /**
   * Get the last valid data point.
   * @returns DataPoint
   */
  getLastValidDataPoint() {
    const result =
      this.dataPoints.findLast((item) => item.isFutureProjection === false) ??
      this.dataPoints.at(0);
    return result;
  }

  /**
   * Get the forecast starting point in time.
   * @returns number (timestamp)
   */
  getForecastStartTime() {
    const lastValidDataPoint = this.getLastValidDataPoint();
    return lastValidDataPoint?.date?.getTime() ?? 0;
  }
}
