import { formatFloatToNumber, formatNumber } from 'shared/utils/float-utils';
import {
  ConsumerOption,
  DataPoint,
  EnvironmentalMonitorVessel,
  KpiStatus,
  Projection,
  Vessel,
} from '../../../_gql/graphql';
import { ICalculation } from '../models/calculations.model';
import { IFleetVessel } from '../models/fleet-vessel.model';
import { IFleetUtilizationAndOperationalProfiles } from '../models/fleet.model';
import { ProjectionConditionsDomain } from '../models/vessel-cii-rating.model';
import { IVesselDomain } from '../models/vessel.model';
import { projectionConditionsToDomain } from './vessel-cii-ratings.mapper';
import { TEXT } from 'shared/constants/text';
import { NA_VALUE_INDICATOR } from 'shared/components/datagrid/DataGrid.component';
import { VoyageComparisonMapper } from './voyage-comparison.mapper';
import { Consumer } from '../models/consumer.model';

export class VesselMapper {
  public static fromDTO(dto: Vessel | undefined): IVesselDomain {
    return {
      id: dto?.id ?? '',
      vesselName: dto?.name ?? NA_VALUE_INDICATOR,
      imoNumber: dto?.imoNumber ?? NA_VALUE_INDICATOR,
      calculations: {
        aer: this.calculationFromDTO(dto?.calculations?.aer?.data ?? []),
        eua: this.calculationFromDTO(dto?.calculations?.eua ?? []),
      },
      engines: this.consumerFromDTO(dto?.consumerOptions ?? undefined),
    };
  }

  public static fromFleetDTO(
    dtos: EnvironmentalMonitorVessel[]
  ): IFleetVessel[] {
    return dtos?.map((dto) => ({
      id: dto.vesselId ?? dto.id,
      name: dto.name ?? NA_VALUE_INDICATOR,
      sisterClass: dto.sisterClass ?? NA_VALUE_INDICATOR,
      class: dto.sisterClass ?? '',
      fuelConsumed: dto.conditionSummary?.fuelConsumed ?? undefined,
      co2Emissions: dto.conditionSummary?.carbonEmitted ?? undefined,
      distance: dto.conditionSummary?.distanceTravelled ?? undefined,
      calculations: {
        aer: {
          value: dto.kpis?.aer?.attained ?? undefined,
          delta: dto.kpis?.aer?.delta ?? undefined,
          status: dto.kpis?.aer?.status ?? KpiStatus.Unknown,
          deviation: dto.kpis?.aer?.deviation ?? undefined,
        },
        cii: {
          value: dto.kpis?.cii?.ciiRating ?? undefined,
          delta: dto.kpis?.cii?.delta ?? undefined,
          required: dto.kpis?.cii?.required ?? undefined,
          status: dto.kpis?.cii?.status ?? KpiStatus.Unknown,
          deviation: dto.kpis?.cii?.deviation ?? undefined,
        },
        ciiPercentage: {
          status: dto.kpis?.ciiPercentage?.status ?? KpiStatus.Unknown,
          deviation: dto.kpis?.ciiPercentage?.deviation ?? undefined,
        },
        eeoi: {
          value: dto.kpis?.eeoi?.actual ?? undefined,
          delta: dto.kpis?.eeoi?.delta ?? undefined,
          status: dto.kpis?.eeoi?.status ?? KpiStatus.Unknown,
          deviation: dto.kpis?.eeoi?.deviation ?? undefined,
        },
        euaAgainstPlanned: {
          value:
            dto.kpis?.euaAgainstPlanned?.vesselEuaPlannedValue ?? undefined,
        },
        euaAgainstFleet: {
          value:
            dto.kpis?.euaAgainstFleet?.value === -1
              ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
              : dto.kpis?.euaAgainstFleet?.value != null
              ? formatNumber(dto.kpis?.euaAgainstFleet?.value)
              : NA_VALUE_INDICATOR,
          status: dto.kpis?.euaAgainstFleet?.status,
        },
        // TODO: Fill in with correct values from DTO
        eua: {
          co2:
            formatFloatToNumber(
              dto.kpis?.euaAgainstPlanned?.vesselEuaCo2 ?? undefined
            ) === -1
              ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
              : formatFloatToNumber(
                  dto.kpis?.euaAgainstPlanned?.vesselEuaCo2 ?? undefined
                ) ?? NA_VALUE_INDICATOR,
          liability:
            dto.kpis?.euaAgainstPlanned?.vesselEuaLiability ?? undefined,
          status: dto.kpis?.euaAgainstPlanned?.status ?? KpiStatus.Unknown,
          euaLiabilityForYear:
            dto.kpis?.euaAgainstPlanned?.vesselEuaLiability ?? undefined === -1
              ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
              : dto.kpis?.euaAgainstPlanned?.vesselEuaLiability === null
              ? NA_VALUE_INDICATOR
              : Math.ceil(
                  dto.kpis?.euaAgainstPlanned?.vesselEuaLiability ?? 0
                ) ?? NA_VALUE_INDICATOR,
          showRag: dto.kpis?.euaAgainstPlanned?.value !== null,
          liabilityPercentage:
            dto.kpis?.euaAgainstPlanned?.value === -1
              ? TEXT.ERROR_MESSAGES.EMPTY_DASHES
              : dto.kpis?.euaAgainstPlanned?.value ?? NA_VALUE_INDICATOR,
          delta: dto.kpis?.euaAgainstPlanned?.delta ?? undefined,
          planned: dto.kpis?.euaAgainstPlanned?.vesselEuaPlannedValue ?? 0,
        },
        fuelEU:
          dto.kpis?.fuelEU?.map((x) => ({
            wellToTank: x?.wellToTank ?? undefined,
            tankToWake: x?.tankToWake ?? undefined,
            wellToWake: x?.ghgIntensity ?? undefined,
            wellToWakeStatus: x?.ghgIntensityStatus ?? undefined,
            complianceBalance: x?.complianceBalance ?? undefined,
            fuelPenalty: x?.fuelPenalty ?? undefined,
            fuelPenaltyAgainstFleetPercentage:
              x?.fuelPenaltyAgainstFleetPercentage ?? undefined,
          })) ?? [],
        mrvEmissions: {
          euCo2: dto.kpis?.mrvEmissions?.euCo2 ?? undefined,
          euCh4: dto.kpis?.mrvEmissions?.euCh4 ?? undefined,
          euN2o: dto.kpis?.mrvEmissions?.euN2o ?? undefined,
          fuelSlippage: dto.kpis?.mrvEmissions?.fuelSlippage ?? undefined,
        },
        ghgMrv: VoyageComparisonMapper.ghgMrvFromDTO(
          dto.kpis?.ghgMrv ?? undefined
        ),
      },
    }));
  }

  private static calculationFromDTO(
    dtos: DataPoint[] | undefined
  ): ICalculation[] {
    return (
      dtos?.map((dto) => ({
        date: dto?.date?.unixTime ?? null,
        deviation: dto?.deviation ?? null,
        value: dto?.value ?? null,
      })) ?? []
    );
  }

  private static consumerFromDTO(
    dtos: ConsumerOption[] | undefined
  ): Consumer[] {
    return (
      dtos?.map((dto) => ({
        id: dto.id,
        name: dto.name ?? NA_VALUE_INDICATOR,
      })) ?? []
    );
  }

  public static fromFleetUtilizationDTO(
    dtos: any
  ): IFleetUtilizationAndOperationalProfiles[] {
    return (
      dtos?.map((dto: any) => ({
        id: dto?.id ?? '',
        VesselName: dto?.name ?? '',
        ImoNumber: dto?.imoNumber ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
        CII: {
          value: dto?.kpis?.cii?.ciiRating ?? TEXT.ERROR_MESSAGES.EMPTY_DASHES,
          status: dto?.kpis?.cii?.status?.toLowerCase() ?? 'unknown',
        },
        AtSea: Math.round(
          dto?.kpis?.utilization?.atSea?.durationPercentage ?? 0
        ),
        Ballast: Math.round(
          dto?.kpis?.utilization?.ballast?.durationPercentage ?? 0
        ),
        Idle: Math.round(dto?.kpis?.utilization?.idle?.durationPercentage ?? 0),
        InPort: Math.round(
          dto?.kpis?.utilization?.inPort?.durationPercentage ?? 0
        ),
        Laden: Math.round(
          dto?.kpis?.utilization?.laden?.durationPercentage ?? 0
        ),
        Maneuvering: Math.round(
          dto?.kpis?.utilization?.maneuvering?.durationPercentage ?? 0
        ),
      })) ?? []
    );
  }

  public static fromUpdatedCiiProjectionsDTO(
    projections: Projection[] | undefined
  ): ProjectionConditionsDomain | undefined {
    if (!projections?.length) return undefined;
    const conditions = projections[0]?.conditions ?? undefined;
    return projectionConditionsToDomain(conditions);
  }
}
