import { ISequence } from 'routes/environmental-monitor/models/plannedItinerary.model';
import { getSequenceRules, ISequenceRule } from './sequence-rule';
import { SequenceValidationResult } from './sequence-validator-result';

const passed = SequenceValidationResult.Passed();
export abstract class SequenceRulesValidator {
  public static Validate(sequences: ISequence[]): SequenceValidationResult {
    const rules = getSequenceRules();
    if (sequences.length === 0 || rules.length === 0) {
      return passed;
    }

    if (sequences.length === 1 && sequences[0]) {
      return this.validateSequenceRules(rules, sequences[0], undefined, true);
    }

    for (let i = 0; i < sequences.length - 1; i++) {
      const lastSequence = sequences[i];
      const thisSequence = sequences[i + 1];
      if (!lastSequence || !thisSequence) {
        continue;
      }
      const isFinalSequence = i === sequences.length - 2;
      const validationResult = this.validateSequenceRules(
        rules,
        lastSequence,
        thisSequence,
        isFinalSequence
      );
      if (!validationResult.valid) {
        return validationResult;
      }
    }
    return passed;
  }
  private static validateSequenceRules(
    rules: ISequenceRule[],
    lastSequence: ISequence,
    thisSequence: ISequence | undefined,
    isFinalSequence: boolean
  ): SequenceValidationResult {
    for (const rule of rules) {
      const validationResult = rule.Validate(
        lastSequence,
        thisSequence,
        isFinalSequence
      );
      if (!validationResult.valid) {
        return validationResult;
      }
    }
    return passed;
  }
}
