import { ISequence } from '../modal/sequence.config';

export class SequenceValidationResult {
  public valid: boolean;
  public id: number;
  public message: string;
  public failingField: keyof ISequence | undefined;

  private constructor(
    valid?: boolean,
    id?: number,
    message?: string,
    failingField?: keyof ISequence
  ) {
    this.valid = valid ?? true;
    this.id = id ?? -1;
    this.message = message ?? '';
    this.failingField = failingField;
  }

  public static Passed(): SequenceValidationResult {
    return new SequenceValidationResult();
  }

  public static Failed(
    id: number,
    message: string,
    failingField?: keyof ISequence
  ): SequenceValidationResult {
    return new SequenceValidationResult(false, id, message, failingField);
  }
}
